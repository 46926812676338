<template>
    <header class="login">
        <van-image class="login_logo" width="100" height="100" :src="require('../../assets/img/login_logo.png')"/>
        <div class="login_title">上海虹桥中药饮片</div>
        <div class="login_subtitle">欢迎使用移动端上海虹桥中药饮片</div>

        <van-form class="login_form" @submit="login">
            <van-field class="form_input" v-model="form.username" name="username" label="账号" placeholder="请输入账号" :rules="rules.username" />
            <van-field class="form_input" v-model="form.password" type="password" name="password" label="密码" placeholder="请输入密码" :rules="rules.password" />
            <div class="form_btn" style="margin: 16px;">
                <van-button round block type="info" native-type="submit"> 登录 </van-button>
            </div>
            <div class="form_jump" v-on:click="jumpTo">企业注册</div>
        </van-form>
    </header>
</template>

<script>
    import { constants } from "crypto";
    export default {
        data() {
            return {
                defaultHeight: '0',  //默认屏幕高度
                nowHeight:  '0',  //实时屏幕高度
                form: {
                    username: "",
                    password: "",
                },
                rules: {
                    username: [{ required: true, message: '请输入账号' }],
                    password: [{ required: true, message: '请输入密码' }]
                }
            };
        },
        methods: {
            login() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                this.$axios.post("/user/loginAdmin", this.form)
                .then(res => {
                    if(res.code == "ok") {
                        toast.clear();
                        this.$toast.success('登录成功');
                        let user = res.data;
                        this.$store.commit("updateLogin", true);
                        localStorage.userInfo = JSON.stringify(user);
                        this.$router.push("/index");
                    } else {
                        toast.clear();
                        this.$toast.success('登录失败');
                    }
                }).catch(err=> {toast.clear();});
            },
            
            jumpTo() {
                this.$router.push("/register");
            }
        },
    };
</script>

<style lang="less" scoped>
    .logo {
        font-size: 100px !important;
        margin-bottom: 150px;
    }

    /* 本页公共样式 */
    .login {
        height: 100vh;
        background-color: #fff;
    }

    header {
        text-align: center;
    }

    // 手机号码
    .login_logo {
        margin: 55px 0 30px;
    }

    .login_title {
        font-size: 24px;
        color: #20a0ff;
        margin: 0 0 10px 0;
    }

    .login_subtitle {
        font-size: 14px;
        color: #afafaf;
        margin: 0 0 40px 0;
    }

    .login_form {
        .form_input {
            margin: 0 0 30px 0;
        }

        .form_btn {
            width: 80%;
            margin: 0 auto !important;
            button {
                background-color: #20a0ff;
            }
        }

        .form_jump {
            margin: 20px 0 0 0;
            color: #20a0ff;
            font-size: 14px;
        }
    }
</style>

